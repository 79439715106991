import React from "react";
import {
  Container,
} from "../styled";
import FormConsultation from "../../../../Forms/Consultation-Classic";

const SmallForm = ({ submitText, formId }) => {

  return (
    <>
      <Container>
        <FormConsultation formId={formId} />
      </Container>
    </>
  );
};

export default SmallForm;
